import React, { useEffect } from "react";
import { Link } from "gatsby";
import ArrowRightICon from "../common/Icons";
export const Card = ({ image, header, url, link, alt, content }) => {
  return (
    <div className="card_landing_section integrations_popular_bg">
      <img src={image} alt={alt} />
      <div className="card_landing_trial">
        <h4>{header}</h4>
        {content && <p> {content}</p>}
        <div className="card_link_landing">
          <Link to={url} className="url_manipulation">
            {link}
            <span className="arrow_svg_link">
              <ArrowRightICon />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export const KeyResources = ({ cards }) => {
  return (
    <section className="trial_cards_page trial_cards_page_img">
      <div className="container">
        <h4 className="resource_header font-section-header">Key Resources</h4>
        <div className="col-12 grid_card_trial">
          <div className="row">
            {cards.map(card => (
              <Card {...card} />
            ))}
            <div className="p-1"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const CardColor = ({ header, url, link, alt, content, text }) => {
  return (
    <>
      <h4 className="flex-0-0 font-section-sub-header-bold-v2 color-black-resource mb-0">{header}</h4>
      {text && <h6 className="line-height-1 min-height-50 font-section-normal-text-resources-medium color-light-gray">{text}</h6>}
      {content && <p className="key-resources-card-text-wrap font-section-normal-text-resources color-black-resource line-height-18 mb-0 pb-2"> {content}</p>}
      <div className="card_link_landing">
        <Link to={url} className="url_manipulation text-decoration-underline font-section-normal-text-medium-small text-underline-offset color-anchor-blue">
          {link}  &nbsp; &#8594;
        
        </Link>
      </div>
    </>
  );
};
export const KeyResourcesColor = ({ cards }) => {
  return (
    <section className="trial_cards_page trial_cards_page_img mb-0">
      <div className="container">
        <h4 className="resource_header font-section-header mb-3">Popular Chatbot Use-Case</h4>
        <div className="col-12 grid_card_trial">
          <div className="row">
            {cards.map(card => {
              let colorChange =
                card.color == "green"
                  ? "#F7FFFA 0% 0% no-repeat padding-box"
                  : card.color == "red"
                  ? "#FFF4F4 0% 0% no-repeat padding-box"
                  : "#FFFFF7 0% 0% no-repeat padding-box";
              return (
                <div
                  className="card_landing_section integrations_popular_bg"
                  style={{ background: colorChange }}
                >
                  <div className="card_landing_trial">
                    <CardColor {...card} />
                  </div>
                </div>
              );
            })}

            <div className="p-1"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
