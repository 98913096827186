import React from "react";
import { useMedia } from "use-media";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import ArrowRightICon from "../../components/common/Icons";
import KeyResources from "../../components/common/KeyResources";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { OnScrollPopup } from "./hr-chatbot";

import { RequestForm } from "../../components/form";
import { CardsThree } from "../conversational-ai-platform";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const card_one = require("../../assets/img/resources_landing/resources_card_1.png");
const card_two = require("../../assets/img/resources_landing/resources_card_2.png");
const card_three = require("../../assets/img/resources_landing/resources_card_3.png");
const card_four = require("../../assets/img/resources_landing/resources_card_4.png");
const card_five = require("../../assets/img/resources_landing/resources_card_5.png");
const card_six = require("../../assets/img/resources_landing/resources_card_6.png");

const topImage = require("../../assets/img/resources_landing/resources_header.png");

const resoucesData = [
  {
    image: card_one,
    header: "Blog",
    content:
      "Read articles over a wide range of topics from the Workativ Team.",
    url: "https://workativ.com/blog",
    link: "Read more",
    alt: "blog",
  },
  {
    image: card_three,
    header: "Videos",
    url: "/conversational-ai-platform/chatbot-videos/",
    content:
      "A gallery of short videos that cover the innovative capabilities of Workativ Assistant.",
    link: "Read more",
    alt: "videoImg",
  },

  {
    image: card_four,
    header: "Whitepapers",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    content:
      "Learn about current trends and digital transformation for workplace support.",
    link: "Read more",
    alt: "Whitepapers",
  },
  {
    image: card_five,
    header: "Knowledge Base",
    url: "https://help.workativ.com/",
    content:
      "Workativ Assistant’s central repository of information on features and implementation.",
    link: "Read more",
    alt: "Knowledge",
  },
  {
    image: card_six,
    header: "What’s new",
    url:
      "https://blog.workativ.com/category/whats-new/?utm_source=Workativ.com%2Fresources&utm_medium=resource%20page&utm_campaign=resource%20page",
    content:
      "Stay up-to-date with Workativ’s latest features and enhancements.",
    link: "Read more",
    alt: "What’s new",
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot Resources | Watch webinars, videos, reads blogs and whitepapers on Conversational AI Chatbot & Automation | Workativ Assistant"
        description="Resources on Conversational AI and Process Automation Solutions. Learn how to build chatbot with process automation."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Free Chatbot Resources | Watch webinars, videos, reads blogs and whitepapers on Conversational AI Chatbot & Automation | Workativ Assistant"
        ogDescription="Resources on Conversational AI and Process Automation Solutions. Learn how to build chatbot with process automation."
        ogImage={topImage}
      />
      <Container>
        <Layout backgroundColor={"resource_bg_header "} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"resource_bg_header"}
            fromPage="assistant-resources"
          >
            <RegisterSection.FormWithContent fromPage="assistant-resources">
              <RegisterSection.MainHeader>
                Useful Resources
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                A place for engaging content from Workativ. Get insights about
                workplace support, AI, Automation, and choose from in-depth
                guides and videos to know more about our product capabilities.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>
          <div className="resources_card">
            <section className="whitepaper_cards">
              <div className="container">
                <div className="row">
                  <ul className="cards">
                    {resoucesData.map((data) => (
                      <li className="cards__item">
                        <div className="card">
                          <div className="card__image">
                            <img
                              loading="lazy"
                              src={data.image}
                              alt={data.alt}
                            ></img>
                          </div>
                          <div className="card__content">
                            <div className="card__title">{data.header}</div>
                            <p className="card__text">{data.content}</p>
                            <div class="card_link_landing">
                              <a class="url_manipulation" href={data.url}>
                                Read more
                                <span class="arrow_svg_link">
                                  <ArrowRightICon />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </div>
          <CardsThree />
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <RequestForm isFooterForm={true} fromPage="assistant-resources" />
          {/* {isSmall ? null : <OnScrollPopup />} */}
        </Layout>
      </Container>
    </>
  );
}
